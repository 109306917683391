import moment from 'moment-timezone';
import api from 'src/services/api';

export default function useScheduleTimezone(facilityId?: string) {
  const { useGetFacilityById } = api.facilityService();
  const { data: facilityData } = useGetFacilityById(facilityId);
  const facilityTimezone = facilityData?.state_time_zones?.[0];

  const userTimezone = moment.tz.guess();

  const timezone = facilityTimezone || userTimezone;
  const offset = moment().tz(timezone).utcOffset() / 60;

  const userZone = moment.tz(userTimezone).zoneAbbr();
  const facilityZone = facilityTimezone ? moment.tz(facilityTimezone).zoneAbbr() : null;

  const zone = facilityZone || userZone;
  return { offset, zone };
}
